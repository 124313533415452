import $ from 'jquery';
export default {
  name: "",
  components: {},
  data() {
    return {
      navList:[
        {
          name:'首页',
          routes:[
            {
              name:'咨询设计',
              link:'/'
            },{
              name:'工程实施',
              link:'/'
            },{
              name:'平台建设',
              link:'/'
            },{
              name:'运维服务',
              link:'/'
            },{
              name:'运营推广',
              link:'/'
            },{
              name:'售后维护',
              link:'/'
            },
          ]
        }
      ],
      xsMenuActive:false
    };
  },
  methods: {
    menusClick(){
      this.xsMenuActive = !this.xsMenuActive;
      $('.hidden-nav').animate({
        opacity:'toggle',
        height:'toggle',
      },'fast')
      
    },
    mobileLink(link,type){
      this.$router.push({ path: link });
      if(type!==undefined){
        setTimeout(() => {
          sessionStorage.setItem('type',type);
        },);
      }
      $('.hidden-nav').css({
        display:'none'
      });
      this.xsMenuActive = !this.xsMenuActive;
    },
    pcLink(link,type){
      this.$router.push({ path: link });
      if(type!==undefined){
        setTimeout(() => {
          sessionStorage.setItem('type',type);
        },);
      }
      $('.hidden-nav').css({
        display:'none'
      });
    },
    newsLink(link,kind){
      this.$router.push({ path: link });
      if(kind!==undefined){
        if(kind===3){
          sessionStorage.removeItem('kind');
        }else{
          setTimeout(() => {
            sessionStorage.setItem('kind',kind);
          },);
        }
      }
    }
  },
  mounted(){
    const _this =this;
    $(window).resize(function(){
      if($(window).width()>=768){
        $('.hidden-nav').css({
          display:'none'
        });
        _this.xsMenuActive = false;
      }
    })
  },
  computed: {},
  watch: {},
};